<template>
  <div class="recruitment-brand">
    <h3>机构组</h3>
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="plus" type="primary" @click="groupModal.open('add')">
          添加机构组
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form inline @submit.prevent="query">
          <el-form-item label="机构组名称">
            <el-input v-model="queryParams.likeName" placeholder="请输入" />
          </el-form-item>
          <el-form-item>
            <el-button icon="search" type="primary" @click="query">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="loading" border :data="tableData" :table-layout="'auto'">
      <el-table-column label="机构组名称" prop="name" />
      <el-table-column label="说明" prop="remark" />
      <el-table-column label="创建时间">
        <template #default="{ row }">{{ parseTime(row.createTime) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="150">
        <template #default="{ row }">
          <el-link type="primary" :underline="false" @click="groupModal.open('edit', row)">
            编辑
          </el-link>
          <el-link type="primary" :underline="false" @click="manage(row)">
            管理
          </el-link>
          <el-link type="danger" :underline="false" @click="del(row)">
            删除
          </el-link>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>

    <el-dialog :title="`${groupModal.type === 'add' ? '添加' : '编辑'}机构组`" :width="600" destroy-on-close v-model="groupModal.visible" @close="groupModal.close()" @closed="groupModal.closed()">
      <el-form v-if="groupModal.data" ref="groupFormRef" :label-width="96" :model="groupModal.data" :rules="groupModal.rules">
        <el-form-item label="机构组名称" prop="name">
          <el-input v-model="groupModal.data.name" placeholder="请输入" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="说明" prop="remark">
          <el-input v-model="groupModal.data.remark" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="groupModal.close()">取消</el-button>
        <el-button type="primary" @click="groupModal.confirm()">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="manageModal.visible" :title="`招聘机构组 - ${manageModal.data?.name}`" :width="980" center destroy-on-close @close="manageModal.close()" @closed="manageModal.closed()">
      <RecruitmentBrandManage :groupId="manageModal.data?.id" />
      <template #footer>
        <el-button @click="manageModal.close()">关闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, toRaw, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router'
import { parseTime } from '@/utils/index';
import { queryRecruitmentBrandGroup, addRecruitmentBrandGroup, editRecruitmentBrandGroup, delRecruitmentBrandGroup } from '@/api/recruitment/brand';
import RecruitmentBrandManage from './RecruitmentBrandManage.vue';

const route = useRoute()
const meetingCode = route.query.meetingCode
const tableData = ref([])
const loading = ref(false)
const { proxy } = getCurrentInstance()

const queryParams = reactive({
  likeName: '',
  meetingCode
})

function query() {
  loading.value = true
  queryRecruitmentBrandGroup(toRaw(queryParams)).then(({ code, data }) => {
    if (code !== 200) return
    tableData.value = data
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  query()
})

const groupFormRef = ref()
const groupModal = reactive({
  visible: false,
  type: 'add',
  data: null,
  rules: {
    name: [{required: true, message: '请输入机构组名称'}]
  },
  open(type, data = { name: '', remark: ''}) {
    this.type = type
    this.data = { ...data }
    this.visible = true
  },
  close() {
    this.visible = false
  },
  closed() {
    this.data = null
  },
  confirm() {
    groupFormRef.value.validate(valid => {
      if (!valid) return
      const params = { meetingCode, ...this.data }
      const api = this.type === 'add' ? addRecruitmentBrandGroup : editRecruitmentBrandGroup
      api(params).then(({ code }) => {
        if (code !== 200) return
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        this.close()
        query()
      })
    })
  }
})

function del(row) {
  proxy.$baseConfirm('确定要删除当前项吗？', null, () => {
    delRecruitmentBrandGroup(row.id).then(({ code }) => {
      if (code !== 200) return
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      query()
    })
  })
}

const manageModal = reactive({
  visible: false,
  data: null,
  open(row) {
    this.data = { ...row }
    this.visible = true
  },
  close() {
    this.visible = false
  },
  closed() {
    this.data = null
  },
})

function manage(row) {
  manageModal.open(row)
}
</script>

<style lang="scss" scoped>
.recruitment-brand {
  padding: 16px;
}
</style>